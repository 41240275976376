import React from "react";
import Main from "././../../components/404/main";
const Index = () => {
  return (
    <div>
      <Main />
    </div>
  );
};

export default Index;
